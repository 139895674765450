<template>
  <div ref="nuxtParent">
    <floating-button v-if="isOpenFloating" />
    <Nuxt />
  </div>
</template>

<script>
import CommonUtil from '~/utils/common-util';
import floatingButton from '@/components/sample/floatingButton.vue';

export default {
  name: 'Default',
  components: {
    floatingButton,
  },
  data() {
    return {
      isOpenFloating: false,
      // 플로팅버튼 표시할 화면
      whiteList: [
        'UPFMFV', // 혜택 전체
        'UPFMEV', // 이벤트 전체
        'UPFMMO0101', // 더보기, 레벨조회 화면
        'DCTest', // DCTest 화면
        'Sample', // 샘플 화면 전체
        'UPFMCM', // 신용관리 전체
        'UPFMJR0501M0', // 주니어 GNB(전체)
      ],
      // 플로팅버튼 표시 불가 화면
      blackList: ['SampleLogin', 'SampleMydataLogin', 'UPDCSTNO'],
    };
  },
  fetch() {
    this.setFloatingOpenState();
  },
  head() {
    // meta-info 미들웨어에서 store에 저장해 두었던 화면의 메타 정보를 가져온다.
    const srnInfo = this.$storageUtil.store.get('srnInfo');
    return {
      title: srnInfo?.headTitle || srnInfo?.title,
      meta: [
        { hid: 'title', name: 'title', content: srnInfo?.title },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: srnInfo?.title,
        },
      ],
    };
  },
  watch: {
    '$route.path'() {
      this.setFloatingOpenState();
    },
  },
  errorCaptured(error) {
    // Sentry 전송
    const { errorObject, errorType } = this.$sentryUtil.getError(error);
    this.$sentryUtil.captureException(errorObject, errorType);
  },
  methods: {
    // 개발용 Floating 버튼 노출 여부 설정
    setFloatingOpenState() {
      const isTestEnv = CommonUtil.isDev() || CommonUtil.isDevlocal() || CommonUtil.isTest() || CommonUtil.isStage();
      if (isTestEnv) {
        this.isOpenFloating =
          this.whiteList.some(srnId => this.$route.path?.toLowerCase().indexOf(srnId.toLowerCase()) >= 0) &&
          !this.blackList.some(srnId => this.$route.path?.toLowerCase().indexOf(srnId.toLowerCase()) >= 0);
      } else {
        this.isOpenFloating = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  @import '@scss/components/dialog/messageDialog.scss';
}
</style>
